@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap');

body {
  // background-color: rgb(33, 37, 43);
  background-color: #000;
  color: white;
}

.status {
  color: rgb(152, 195, 121);
  margin: 2em;
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// body {
//   background-color: black;
//   color: white;
// }

.app-grid {
  display: flex;
  flex-wrap: wrap;
  align-items:flex-start;
  align-content:flex-start;
}

.pin-bottom {
  font-family: 'Fira Code', monospace;
  position: absolute;
  bottom: 0;
  right: 0;
  h1 {
    font-size: 10em;
    font-weight: 700;
    margin: 0;
  }
  margin-bottom: 0;
}

.run-button {
  background-color: rgba(white, 0.1);
  color: white;
  cursor: pointer;
  transition: opacity .25s;
  border: none;
  border-radius: 20px;
  padding-left: 1em;
  padding-right: 1em;
  height: 40px;
  margin-top: -5px;
  margin: 2em;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:focus, &:active {
    outline: none;
  }

  & > img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
  }

  &:hover {
      opacity: 0.5;
  }
}